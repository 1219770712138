import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

const initialState = {
  companyDetails: [],
  siteEnroll: [],
  siteEnrolloptimized: [],
  clientEnroll: [],
  divisionEnroll: [],
  divisionEnrolloptimized: [],
  departmentEnroll: [],
  departmentEnrolloptimized: [],
  glcodeEnroll: [],
  lobEnroll: [],
  lobEnrolloptimized: [],
  lobTotalCount: '0',
  lobTotalCountoptimized: '0',
  teamsTotalCount: '0',
  teamsTotalCountoptimized: '0',
  auxEnroll: [],
  kpiEnroll: [],
  acessProfileEnroll: [],
  userRoleEnroll: [],
  holidayEnroll: [],
  revenueCycleEnroll: [],
  currencyEnroll: [],
  directMangerRequestEnroll: [],
  indirectMangerRequestEnroll: [],
  teamsEnroll: [],
  teamsEnrolloptimized: []
};

const slice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {
    companyDetailsredux: (state, action) => {
      state.companyDetails = action.payload;
    },
    siteEnrollredux: (state, action) => {
      state.siteEnroll = action.payload;
    },
    siteEnrollreduxoptimized: (state, action) => {
      state.siteEnrolloptimized = action.payload;
    },
    clientEnrollredux: (state, action) => {
      state.clientEnroll = action.payload;
    },
    divisionEnrollredux: (state, action) => {
      state.divisionEnroll = action.payload;
    },
    divisionEnrollreduxoptimized: (state, action) => {
      state.divisionEnrolloptimized = action.payload;
    },
    departmentEnrollredux: (state, action) => {
      state.departmentEnroll = action.payload;
    },
    departmentEnrollreduxoptimized: (state, action) => {
      state.departmentEnrolloptimized = action.payload;
    },
    lobEnrollredux: (state, action) => {
      state.lobEnroll = action.payload;
    },
    lobEnrollreduxoptimized: (state, action) => {
      state.lobEnrolloptimized = action.payload;
    },
    lobTotalCountredux: (state, action) => {
      state.lobTotalCount = action.payload;
    },
    lobTotalCountreduxoptimized: (state, action) => {
      state.lobTotalCountoptimized = action.payload;
    },
    teamsTotalCountredux: (state, action) => {
      state.teamsTotalCount = action.payload;
    },
    teamsTotalCountreduxoptimized: (state, action) => {
      state.teamsTotalCountoptimized = action.payload;
    },
    glcodeEnrollredux: (state, action) => {
      state.glcodeEnroll = action.payload;
    },
    auxEnrollredux: (state, action) => {
      state.auxEnroll = action.payload;
    },
    kpiEnrollredux: (state, action) => {
      state.kpiEnroll = action.payload;
    },
    acessProfileEnrollredux: (state, action) => {
      state.acessProfileEnroll = action.payload;
    },
    userRoleEnrollredux: (state, action) => {
      state.userRoleEnroll = action.payload;
    },
    holidayEnrollredux: (state, action) => {
      state.holidayEnroll = action.payload;
    },
    revenueCycleEnrollredux: (state, action) => {
      state.revenueCycleEnroll = action.payload;
    },
    directMangerRequestEnrollredux: (state, action) => {
      state.directMangerRequestEnroll = JSON.parse(JSON.stringify(action.payload));
    },
    indirectMangerRequestEnrollredux: (state, action) => {
      state.indirectMangerRequestEnroll = JSON.parse(JSON.stringify(action.payload));
    },
    teamsEnrollredux: (state, action) => {
      state.teamsEnroll = action.payload;
    },
    teamsEnrollreduxoptimized: (state, action) => {
      state.teamsEnrolloptimized = action.payload;
    },
    currencyEnrollredux: (state, action) => {
      state.currencyEnroll = action.payload;
    },
    settoinitailState(state) {
      state.companyDetails = initialState.companyDetails;
      state.siteEnroll = initialState.siteEnroll;
      state.siteEnrolloptimized = initialState.siteEnrolloptimized;
      state.divisionEnroll = initialState.divisionEnroll;
      state.divisionEnrolloptimized = initialState.divisionEnrolloptimized;
      state.departmentEnroll = initialState.departmentEnroll;
      state.departmentEnrolloptimized = initialState.departmentEnrolloptimized;
      state.glcodeEnroll = initialState.glcodeEnroll;
      state.lobEnroll = initialState.lobEnroll;
      state.lobEnrolloptimized = initialState.lobEnrolloptimized;
      state.lobTotalCount = initialState.lobTotalCount;
      state.lobTotalCountoptimized = initialState.lobTotalCountoptimized;
      state.teamsTotalCount = initialState.teamsTotalCount;
      state.teamsTotalCountoptimized = initialState.teamsTotalCountoptimized;
      state.auxEnroll = initialState.auxEnroll;
      state.kpiEnroll = initialState.kpiEnroll;
      state.acessProfileEnroll = initialState.auxEnroll;
      state.userRoleEnroll = initialState.auxEnroll;
      state.holidayEnroll = initialState.holidayEnroll;
      state.revenueCycleEnroll = initialState.revenueCycleEnroll;
      state.directMangerRequestEnroll = initialState.directMangerRequestEnroll;
      state.indirectMangerRequestEnroll = initialState.indirectMangerRequestEnroll;
      state.currencyEnroll = initialState.currencyEnroll;
      state.teamsEnroll = initialState.teamsEnroll;
      state.teamsEnrolloptimized = initialState.teamsEnrolloptimized;
    }
  }
});
export const {
  companyDetailsredux,
  siteEnrollredux,
  siteEnrollreduxoptimized,
  clientEnrollredux,
  divisionEnrollredux,
  divisionEnrollreduxoptimized,
  departmentEnrollredux,
  departmentEnrollreduxoptimized,
  lobEnrollredux,
  lobEnrollreduxoptimized,
  glcodeEnrollredux,
  auxEnrollredux,
  kpiEnrollredux,
  acessProfileEnrollredux,
  userRoleEnrollredux,
  holidayEnrollredux,
  revenueCycleEnrollredux,
  lobTotalCountredux,
  lobTotalCountreduxoptimized,
  teamsTotalCountredux,
  teamsTotalCountreduxoptimized,
  directMangerRequestEnrollredux,
  indirectMangerRequestEnrollredux,
  currencyEnrollredux,
  teamsEnrollredux,
  teamsEnrollreduxoptimized
} = slice.actions;
export default slice.reducer;
