// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  clients: '/clients',
  siteslist: '/siteslist',
  division: '/division',
  departments: '/departments',
  lineofbusiness: '/lineofbusiness',
  glCode: '/GLCode',
  userRoles: '/userRoles',
  accessProfile: '/accessProfile',
  auxes: '/auxes',
  KpiMaster: '/KpiMaster',
  users: '/users',
  userlevel: '/userlevel',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    payroll: path(ROOTS_DASHBOARD, '/payroll'),
    requests: path(ROOTS_DASHBOARD, '/requests'),
    employees: path(ROOTS_DASHBOARD, '/employees'),
    myprofile: path(ROOTS_DASHBOARD, '/myprofile'),
    revenue: path(ROOTS_DASHBOARD, '/revenue'),
    reports: path(ROOTS_DASHBOARD, '/reports'),
    billing: path(ROOTS_DASHBOARD, '/billing')
  },
  settings: {
    companydetails: path(ROOTS_DASHBOARD, '/settings/companydetails'),
    clients: path(ROOTS_DASHBOARD, '/settings/clients'),
    siteslist: path(ROOTS_DASHBOARD, '/settings/siteslist'),
    division: path(ROOTS_DASHBOARD, '/settings/division'),
    departments: path(ROOTS_DASHBOARD, '/settings/departments'),
    lineofbusiness: path(ROOTS_DASHBOARD, '/settings/lineofbusiness'),
    glCode: path(ROOTS_DASHBOARD, '/settings/GLCode'),
    auxes: path(ROOTS_DASHBOARD, '/settings/auxes'),
    KpiMaster: path(ROOTS_DASHBOARD, '/settings/KpiMaster'),
    accessProfile: path(ROOTS_DASHBOARD, '/settings/accessProfile'),
    userRoles: path(ROOTS_DASHBOARD, '/settings/userRoles'),
    userlevel: path(ROOTS_DASHBOARD, '/settings/userlevel'),
    users: path(ROOTS_DASHBOARD, '/settings/users'),
    columnmapping: path(ROOTS_DASHBOARD, '/settings/columnmapping'),
    lobconfig: path(ROOTS_DASHBOARD, '/settings/lobconfig'),
    payrollcycle: path(ROOTS_DASHBOARD, '/settings/payrollcycle'),
    holidayslist: path(ROOTS_DASHBOARD, '/settings/holidayslist'),
    revenuecycle: path(ROOTS_DASHBOARD, '/settings/revenuecycle'),
    runannouncement: path(ROOTS_DASHBOARD, '/settings/runannouncement'),
    teams: path(ROOTS_DASHBOARD, '/settings/teamconfig')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/myprofile')
  },
  settingsroot: {
    companymasterRoot: path(ROOTS_DASHBOARD, '/settings/companymaster/'),
    usermanagementRoot: path(ROOTS_DASHBOARD, '/settings/userManagement/'),
    configurationRoot: path(ROOTS_DASHBOARD, '/settings/configuration/')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
