// routes
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswerOutlined';
import PaidIcon from '@material-ui/icons/PaidOutlined';
import Billing from '@material-ui/icons/RequestQuoteOutlined';
import Setting from '@material-ui/icons/SettingsOutlined';
import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import Label from '../../components/Label';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
// );

// const ICONS = {
//   blog: getIcon('ic_blog'),
//   cart: getIcon('ic_cart'),
//   chat: getIcon('ic_chat'),
//   mail: getIcon('ic_mail'),
//   user: getIcon('ic_user'),
//   kanban: getIcon('ic_kanban'),
//   banking: getIcon('ic_banking'),
//   calendar: getIcon('ic_calendar'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   booking: getIcon('ic_booking')
// };

const size = {
  width: '16px',
  height: '16px'
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'Payroll and Attendance',
        icon: <PanToolOutlinedIcon sx={size} />,
        path: PATH_DASHBOARD.general.payroll
      },
      {
        title: 'Requests',
        icon: <QuestionAnswerIcon sx={size} />,
        path: PATH_DASHBOARD.general.requests
      },
      // { title: 'My Profile', icon: <PersonIcon />, path: PATH_DASHBOARD.general.myprofile },
      {
        title: 'Reports',
        icon: <TrendingUpOutlinedIcon sx={size} />,
        path: PATH_DASHBOARD.general.reports
      },
      { title: 'Revenue', icon: <PaidIcon sx={size} />, path: PATH_DASHBOARD.general.revenue },
      { title: 'Billing', icon: <Billing sx={size} />, path: PATH_DASHBOARD.general.billing }
    ]
  },

  // Settings
  // ----------------------------------------------------------------------
  {
    subIcon: <Setting sx={size} />,
    subheader: 'Settings',
    items: [
      // MANAGEMENT : company master
      {
        title: 'Company Master',
        // icon: <BusinessIcon />,
        path: PATH_DASHBOARD.settingsroot.companymasterRoot,
        children: [
          { title: 'Company Details', path: PATH_DASHBOARD.settings.companydetails },
          { title: 'Clients', path: PATH_DASHBOARD.settings.clients },
          { title: 'Sites', path: PATH_DASHBOARD.settings.siteslist },
          { title: 'Division', path: PATH_DASHBOARD.settings.division },
          { title: 'Departments', path: PATH_DASHBOARD.settings.departments },
          { title: 'Line Of Business', path: PATH_DASHBOARD.settings.lineofbusiness },
          { title: 'Teams', path: PATH_DASHBOARD.settings.teams },
          { title: 'GL Codes', path: PATH_DASHBOARD.settings.glCode },
          { title: 'Aux', path: PATH_DASHBOARD.settings.auxes },
          { title: 'KPI', path: PATH_DASHBOARD.settings.KpiMaster }
        ]
      },
      // MANAGEMENT : user managemnt
      {
        title: 'User Management',
        // icon: <ManageAccountsIcon />,
        path: PATH_DASHBOARD.settingsroot.usermanagementRoot,
        children: [
          { title: 'Access Profile', path: PATH_DASHBOARD.settings.accessProfile },
          { title: 'User Role', path: PATH_DASHBOARD.settings.userRoles },
          // { title: 'User Level', path: PATH_DASHBOARD.settings.userlevel },
          { title: 'Users', path: PATH_DASHBOARD.settings.users }
        ]
      },
      // MANAGEMENT : Configuration
      {
        title: 'Configuration',
        // icon: ICONS.blog,
        path: PATH_DASHBOARD.settingsroot.configurationRoot,
        children: [
          // MANAGEMENT : USER
          { title: 'Column Mapping', path: PATH_DASHBOARD.settings.columnmapping },
          { title: 'Payroll Cycles', path: PATH_DASHBOARD.settings.payrollcycle },
          { title: 'Holidays List', path: PATH_DASHBOARD.settings.holidayslist },
          { title: 'Revenue Cycles', path: PATH_DASHBOARD.settings.revenuecycle },
          { title: 'LOB Config', path: PATH_DASHBOARD.settings.lobconfig },
          { title: 'Run Announcement', path: PATH_DASHBOARD.settings.runannouncement }
        ]
      }
    ]
  }
];

export default sidebarConfig;
